const VISITED_IDENTIFIER = "@@@_VISITED_@@@";
/**
 * Removes from given object the property vie passed name.
 * By default, recursively for all child objects as well.
 */ function removePropertyByName(obj, propertyName) {
    let doRecursive = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    return removePropertiesByName(obj, [
        propertyName
    ], doRecursive);
}
/**
 * Removes from given object the properties vie passed names.
 * By default, recursively for all child objects as well.
 */ function removePropertiesByName(obj, propertyNames) {
    let doRecursive = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    if (obj === null || obj === undefined || obj[VISITED_IDENTIFIER] || (propertyNames === null || propertyNames === void 0 ? void 0 : propertyNames.length) === 0) {
        return obj;
    }
    obj[VISITED_IDENTIFIER] = true;
    for(const prop in obj){
        if (propertyNames.includes(prop)) {
            delete obj[prop];
        } else if (doRecursive && typeof obj[prop] === "object" && obj[prop] !== null) {
            const nestedObj = obj[prop];
            if (!nestedObj[VISITED_IDENTIFIER]) {
                removePropertiesByName(nestedObj, propertyNames, doRecursive);
            }
        }
    }
    delete obj[VISITED_IDENTIFIER];
    return obj;
}
/**
 * Extracts specified properties from an object and returns a new object
 * containing only those properties.
 *
 * @param obj - The source object to extract properties from.
 * @param properties - An array of property names to extract.
 * @param skipNullOrUndefined - null or undefined will be skipped.
 * @returns A new object containing only the extracted properties.
 */ function extractProperties(obj, properties) {
    let skipNullOrUndefined = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    const result = {};
    for (const key of properties){
        if (key in obj) {
            if (skipNullOrUndefined && (obj[key] === null || obj[key] === undefined)) {
                continue;
            }
            result[key] = obj[key];
        }
    }
    return result;
}
export { extractProperties, removePropertiesByName, removePropertyByName };
