import { ERouteHttpVerb } from "@devowl-wp/api";
const routeLocationSupportedLanguagesGet = {
    path: "/supported-languages",
    method: ERouteHttpVerb.GET
};
var ESupportedLanguage;
/**
 * Listing our supported languages.
 *
 * @see https://app.clickup.com/t/863g5edjt (CU-863g5edjt)
 */ (function(ESupportedLanguage) {
    ESupportedLanguage["cs"] = "cs";
    ESupportedLanguage["da"] = "da";
    ESupportedLanguage["nl"] = "nl";
    ESupportedLanguage["nl_formal"] = "nl_formal";
    ESupportedLanguage["nl_informal"] = "nl_informal";
    ESupportedLanguage["en"] = "en";
    ESupportedLanguage["es"] = "es";
    ESupportedLanguage["fi"] = "fi";
    ESupportedLanguage["fr"] = "fr";
    ESupportedLanguage["de"] = "de";
    ESupportedLanguage["de_formal"] = "de_formal";
    ESupportedLanguage["de_informal"] = "de_informal";
    ESupportedLanguage["el"] = "el";
    ESupportedLanguage["hu"] = "hu";
    ESupportedLanguage["it"] = "it";
    ESupportedLanguage["no"] = "no";
    ESupportedLanguage["pl"] = "pl";
    ESupportedLanguage["pt"] = "pt";
    ESupportedLanguage["rm"] = "rm";
    ESupportedLanguage["sk"] = "sk";
    ESupportedLanguage["sv"] = "sv";
})(ESupportedLanguage || (ESupportedLanguage = {}));
/**
 * Listing our supported languages mapped to known DeepL API languages.
 */ const deeplLanguageMap = {
    ["cs"]: "CS",
    ["da"]: "DA",
    ["nl"]: "NL",
    ["nl_formal"]: "nl_formal",
    ["nl_informal"]: "nl_informal",
    ["en"]: "EN",
    ["es"]: "ES",
    ["fi"]: "FI",
    ["fr"]: "FR",
    ["de"]: "DE",
    ["de_formal"]: "de_formal",
    ["de_informal"]: "de_informal",
    ["el"]: "EL",
    ["hu"]: "HU",
    ["it"]: "IT",
    ["no"]: "NB",
    ["pl"]: "PL",
    ["pt"]: "PT-PT",
    ["rm"]: "RO",
    ["sk"]: "SK",
    ["sv"]: "SV"
};
/** The T–V distinction is the contextual use of different pronouns that exists in some languages and serves to convey formality or familiarity. */ const TV_DISTINCT_LANGUAGES = [
    "de",
    "nl"
];
const DEFAULT_LANGUAGE = "en";
/**
 * Converts a supported language code to the corresponding Deepl API language code.
 *
 * @param supportedLanguage - The supported language code.
 * @returns - The Deepl API language code, or undefined if the language is not supported.
 */ function getDeeplLanguageCode(supportedLanguage) {
    return deeplLanguageMap[supportedLanguage];
}
/**
 * Find supported languages as key string of ESupportedLanguage.
 */ function getSupportedLanguageKeys() {
    return Object.keys(ESupportedLanguage).filter((language)=>{
        return isNaN(Number(language));
    });
}
/**
 * Find supported languages as ESupportedLanguage array.
 */ function getSupportedLanguages() {
    let includeTVDistinctLanguages = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
    let languages = Object.keys(ESupportedLanguage).filter((language)=>{
        return isNaN(Number(language));
    }).map((key)=>ESupportedLanguage[key]);
    if (!includeTVDistinctLanguages) {
        languages = languages.filter((lang)=>!TV_DISTINCT_LANGUAGES.includes(lang));
    }
    return languages;
}
export { DEFAULT_LANGUAGE, TV_DISTINCT_LANGUAGES, getDeeplLanguageCode, getSupportedLanguages, getSupportedLanguageKeys, ESupportedLanguage, routeLocationSupportedLanguagesGet };
