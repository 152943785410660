import { DEFAULT_LANGUAGE } from "@devowl-wp/api-real-cookie-banner";
import { useQuery } from "@tanstack/react-query";
import { getTranslation } from "../api/real-cookie-banner";
import { useStores } from "../store";
import { QueryKeys } from "../util/query-keys";
/**
 * TODO: Mario
 * @param targetLanguage
 * @param text
 * @returns
 */ function useTranslate(targetLanguage, text) {
    const { rcbAuthenticationStore } = useStores();
    const releaseQuery = useQuery(QueryKeys.getTranslationByTargetAndText(targetLanguage, text), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getTranslation({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            body: {
                options: {
                    target: targetLanguage,
                    text: text
                }
            }
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!targetLanguage && !!text
    });
    return releaseQuery;
}
/**
 * TODO:
 * @param targetLanguage
 * @param text
 * @param rcbAuthenticationStore
 * @returns
 */ async function fetchTranslation(targetLanguage, text, rcbAuthenticationStore) {
    if (targetLanguage && text && rcbAuthenticationStore) {
        var _rcbAuthenticationStore_session;
        const response = await getTranslation({
            signal: new AbortController().signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            body: {
                options: {
                    source: DEFAULT_LANGUAGE,
                    target: targetLanguage,
                    text: text
                }
            }
        });
        return response;
    }
    return undefined;
}
export { useTranslate, fetchTranslation };
